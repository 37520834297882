<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :btnFlag="btnFlag"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :rules="rules"
      ref="appList"
    >
      <div class="search" slot="subSlot">


      </div>
    </app-list>

  </div>
</template>

<script>
import api from "@/api/common";

export default {
  data() {

    return {
      rules: {
        price: [
          { required: true, message: "请填写面值", trigger: "blur" },

          { type: "number", message: "金额必须为数字值" },
        ],
        fullReduction: [
          { required: true, message: "请填写使用门槛", trigger: "blur" },
          { type: "number", message: "金额必须为数字值" },
        ],
        inventory: [
          { required: true, message: "请填写库存", trigger: "blur" },
          { type: "number", message: "库存必须为数字值" },
        ],
        validStartTime: [
          { required: true, message: "请选择有效期", trigger: "change" },
        ],
        receiveStartTime: [
          { required: true, message: "请选择领取期限", trigger: "change" },
        ],
      },
      dialogShow: false,
      remote: api,
      apiName: "/couponUser/record",
      handleType: "",
      searchVal: "",
      form: {},
      btnFlag: { addShow: false, delShow: false },
      searchQuery: {
        siteName: "",
        siteCode: "",
        refreshNum: 0,
        backstage: true,
      },

      props: [
        {
          label: "优惠券Id",
          prop: "couponId",
          align: "center",
        },

        {
          label: "优惠券面额",
          prop: "price",
          align: "center",
        },

        {
          label: "用户",
          prop: "userName",
          align: "center",
        },

        {
          label: "领取时间",
          prop: "useTime",
          align: "center",
        },
      ],
      formProps: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    async setCouponPutAway(value) {
      if (!this.$refs.appList.selecTionArr.length) {
        this.$message.error("请至少选择一条数据！");
        return;
      }

      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });

      try {
        let ids = [];
        this.$refs.appList.selecTionArr.forEach((element) => {
          ids.push(element.id);
        });

        let res = await api.commonPost({
          apiName: "/coupon/isPutaway",
          ids: ids,
          isPutaway: value,
        });
        this.$message.success(res.message);
        this.searchQuery.refreshNum++;
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    showAdd() {
      this.form = {};
      this.handleType = "create";
      this.dialogShow = true;
    },

    showEdit(row) {
      this.form = row;

      this.form.validTime = [this.form.validStartTime, this.form.validEndTime];
      this.form.receiveTime = [
        this.form.receiveStartTime,
        this.form.receiveEndTime,
      ];

      this.handleType = "update";
      this.dialogShow = true;
    },

    handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (!(this.form.price < this.form.fullReduction)) {
            this.$message.error("优惠券面额不允许超过门槛金额！");
            return;
          }

          let loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.5)",
          });
          try {
            let obj = Object.assign({}, this.form, {
              apiName: "/coupon/" + this.handleType,
            });
            let res = await api.commonPost(obj);
            this.$message.success(res.message);
            this.searchQuery.refreshNum++;
            this.dialogShow = false;
          } catch (error) {
            console.log(error);
          } finally {
            loading.close();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getReceiveTime(e) {
      console.log(e);

      this.form.receiveStartTime = e[0];
      this.form.receiveEndTime = e[1];
    },

    getValidTime(e) {
      this.form.validStartTime = e[0];
      this.form.validEndTime = e[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.fullWidth {
  width: 100% !important;
}
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
